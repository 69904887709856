@import "@/assets/styles/variables.scss";
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
* {
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  //border: 1px solid red;
  backface-visibility: hidden;
  transform: translateZ(0);
  box-sizing: border-box;
  outline: none;
  :focus {
    outline: none !important;
  }
}
html {
  //scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
}

.pointer {
  cursor: pointer;
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1 !important;
}

button,
a {
  appearance: none;
  border: none;
  background-color: transparent !important;
}

.pointer {
  cursor: pointer;
}

/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
} */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s easecubic-bezier(0.37, 0, 0.63, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

// FLEXBOX

.flex-0-0-auto {
  flex: 0 0 auto !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.w-fit-content {
  width: fit-content !important;
}

.w-auto {
  width: auto !important;
}

.w-0 {
  width: 0% !important;
}

.w-55 {
  width: 55% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.h-auto {
  height: auto !important;
}

.h-0 {
  height: 0% !important;
}

.h-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-50 {
  height: 50% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.min-w-100 {
  min-width: 100% !important;
}

.min-w-90 {
  min-width: 90% !important;
}

.min-w-80 {
  min-width: 80% !important;
}

.min-w-70 {
  min-width: 70% !important;
}

.min-w-60 {
  min-width: 60% !important;
}

.min-w-50 {
  min-width: 50% !important;
}

.min-h-100 {
  min-height: 100% !important;
}

.min-w-100vw {
  min-width: 100vw !important;
}

.min-w-200vw {
  min-width: 200vw !important;
}
.min-h-100vw {
  min-height: 100vw !important;
}

// TEXTS

.font-w-300 {
  font-weight: 300 !important;
}

.font-w-400 {
  font-weight: 400 !important;
}

.font-w-500 {
  font-weight: 500 !important;
}

.font-w-600 {
  font-weight: 600 !important;
}

.font-w-700 {
  font-weight: 700 !important;
}

.font-w-800 {
  font-weight: 800 !important;
}

.font-w-900 {
  font-weight: 900 !important;
}

.text-vertical {
  position: absolute;
  background: none;
  transform: rotate(-90deg);
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// TEXT SIZES

.font-rem-0-5 {
  font-size: 0.5rem;
}
.font-rem-0-6 {
  font-size: 0.6rem;
}
.font-rem-0-65 {
  font-size: 0.65rem;
}
.font-rem-0-7 {
  font-size: 0.7rem;
}
.font-rem-0-75 {
  font-size: 0.75rem;
}
.font-rem-0-8 {
  font-size: 0.8rem;
}
.font-rem-0-85 {
  font-size: 0.85rem;
}
.font-rem-0-9 {
  font-size: 0.9rem;
}
.font-rem-1 {
  font-size: 1rem;
}

.font-rem-1-1 {
  font-size: 1.1rem;
}

.font-rem-1-15 {
  font-size: 1.15rem;
}
.font-rem-1-25 {
  font-size: 1.25rem;
}

.font-rem-1-5 {
  font-size: 1.5rem;
}

.font-rem-1-75 {
  font-size: 1.75rem;
}
.font-rem-1-8 {
  font-size: 1.8rem;
}
.font-rem-1-85 {
  font-size: 1.85rem;
}

.font-rem-2 {
  font-size: 2rem;
}

// TEXT VW

.font-vw-1 {
  font-size: 1vw !important;
}

.font-vw-1-1 {
  font-size: 1.1vw !important;
}

.font-vw-1-15 {
  font-size: 1.15vw !important;
}

.font-vw-1-25 {
  font-size: 1.25vw !important;
}

.font-vw-1-5 {
  font-size: 1.5vw !important;
}

.font-vw-1-8 {
  font-size: 1.8vw !important;
}

.font-vw-1-2 {
  font-size: 1.2vw !important;
}

.font-vw-1-25 {
  font-size: 1.25vw !important;
}

.font-vw-1-3 {
  font-size: 1.3vw !important;
}

.font-vw-1-35 {
  font-size: 1.35vw !important;
}

.font-vw-1-5 {
  font-size: 1.5vw !important;
}

.font-vw-1-6 {
  font-size: 1.6vw !important;
}

.font-vw-1-7 {
  font-size: 1.7vw !important;
}
.font-vw-1-8 {
  font-size: 1.8vw !important;
}
.font-vw-2 {
  font-size: 2vw !important;
}

.font-vw-2-5 {
  font-size: 2.5vw !important;
}

.font-vw-3 {
  font-size: 3vw !important;
}

.font-vw-3-15 {
  font-size: 3.15vw !important;
}

.font-vw-3-25 {
  font-size: 3.25vw !important;
}

.font-vw-3-5 {
  font-size: 3.5vw !important;
}

.font-vw-4 {
  font-size: 4vw !important;
}

.font-vw-5 {
  font-size: 5vw !important;
}

.font-vw-6 {
  font-size: 6vw !important;
}
.font-vw-6-5 {
  font-size: 6.5vw !important;
}
.font-vw-7 {
  font-size: 7vw !important;
}
.font-vw-7-5 {
  font-size: 7.5vw !important;
}
.font-vw-8 {
  font-size: 8vw !important;
}
.font-vw-8-5 {
  font-size: 8.5vw !important;
}
.font-vw-9 {
  font-size: 9vw !important;
}
.font-vw-10 {
  font-size: 10vw !important;
}

.font-vw-10-25 {
  font-size: 10.25vw !important;
}

.font-vw-15 {
  font-size: 10.5vw !important;
}

.font-vw-17-5 {
  font-size: 17.5vw !important;
}

.font-vw-20 {
  font-size: 20vw !important;
}

// TEXT UTILITIES

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}

.ls-1r {
  letter-spacing: 1rem;
}

.ls-2r {
  letter-spacing: 2rem;
}

.ls-3r {
  letter-spacing: 3rem;
}

// Z-INDEX

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.z-6 {
  z-index: 6;
}

.z-7 {
  z-index: 7;
}

.z-8 {
  z-index: 8;
}

.z-9 {
  z-index: 9;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-1000 {
  z-index: 1000;
}

.z-7000 {
  z-index: 7000;
}

.z-8000 {
  z-index: 8000;
}

.z-8500 {
  z-index: 8500;
}

.z-9000 {
  z-index: 9000;
}

.z-10000 {
  z-index: 10000;
}

.z-99999 {
  z-index: 99999;
}

// OPACITY

.opacity-0 {
  opacity: 0 !important;
}
.opacity-1 {
  opacity: 0.2 !important;
}
.opacity-2 {
  opacity: 0.4 !important;
}
.opacity-3 {
  opacity: 0.6 !important;
}
.opacity-4 {
  opacity: 0.8 !important;
}
.opacity-5 {
  opacity: 1 !important;
}

// COLORS

// BACKGROUND

.bg-dark-blue {
  background-color: $dark-blue !important;
}

.bg-dark-green {
  background-color: $dark-green !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-summer-sky {
  background-color: $summer-sky !important;
}

.bg-summer-sky-0-25 {
  background-color: rgba($summer-sky, 0.25) !important;
}
.bg-summer-sky-0-5 {
  background-color: rgba($summer-sky, 0.5) !important;
}

.bg-summer-sky-0-75 {
  background-color: rgba($summer-sky, 0.75) !important;
}
.bg-dark-green-0-25 {
  background-color: rgba($dark-green, 0.25) !important;
}
.bg-dark-green-0-5 {
  background-color: rgba($dark-green, 0.5) !important;
}

.bg-dark-green-0-75 {
  background-color: rgba($dark-green, 0.75) !important;
}

.bg-linear-sky {
  background: linear-gradient(
    0deg,
    rgb(30, 30, 66),
    rgb(35, 35, 88) 40%,
    rgb(35, 35, 88) 60%,
    rgb(30, 30, 66) 100%
  );
}
// HEADINGS

// UTILS

.p-50-50 {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.p-0-0-0-0 {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.w-fit-content {
  width: fit-content;
}

.object-fit-cover {
  object-fit: cover;
}

.rotate--90 {
  transform: rotate(-90deg);
}

.absolute-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.w-95 {
  width: 95% !important;
}

.w-90 {
  width: 90% !important;
}

.w-85 {
  width: 85% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-65 {
  width: 65% !important;
}

.w-60 {
  width: 60% !important;
}

.w-55 {
  width: 55% !important;
}

.w-50 {
  width: 50% !important;
}

.w-45 {
  width: 45% !important;
}

.w-40 {
  width: 40% !important;
}

.w-35 {
  width: 35% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

//WVW

.w-20vw {
  width: 20vw !important;
}

.w-15vw {
  width: 15vw !important;
}

.w-10vw {
  width: 10vw !important;
}

.w-5vw {
  width: 5vw !important;
}

.w-2-5vw {
  width: 2.5vw !important;
}

.w-2vw {
  width: 2vw !important;
}

.w-1-5vw {
  width: 1.5vw !important;
}

// HVH

.h-20vh {
  height: 20vh !important;
}

.h-15vh {
  height: 15vh !important;
}

.h-10vh {
  height: 10vh !important;
}

.h-5vh {
  height: 5vh !important;
}

// min-HVH

.min-h-50vh {
  min-height: 20vh !important;
}

.min-h-40vh {
  min-height: 20vh !important;
}

.min-h-30vh {
  min-height: 20vh !important;
}

.min-h-20vh {
  min-height: 20vh !important;
}

.min-h-15vh {
  min-height: 15vh !important;
}

.min-h-10vh {
  min-height: 10vh !important;
}

.min-h-5vh {
  min-height: 5vh !important;
}

// Borders

.border-radius-0-5r {
  border-radius: 0.5rem !important;
}

.border-radius-1r {
  border-radius: 1rem !important;
}

.border-radius-1-5r {
  border-radius: 1.5rem !important;
}

.border-radius-2r {
  border-radius: 2rem !important;
}

.border-radius-0-75vw {
  border-radius: 0.75vw !important;
}

.border-radius-1vw {
  border-radius: 1vw !important;
}
.border-radius-1-25vw {
  border-radius: 1.25vw !important;
}

.border-radius-1-5vw {
  border-radius: 1.5vw !important;
}

.border-radius-2vw {
  border-radius: 2vw !important;
}

.border-radius-4vw {
  border-radius: 4vw !important;
}

.border-radius-6vw {
  border-radius: 6vw !important;
}

.border-radius-10vw {
  border-radius: 10vw !important;
}

.border-radius-14vw {
  border-radius: 14vw !important;
}

.border-radius-16vw {
  border-radius: 16vw !important;
}

.border-radius-20vw {
  border-radius: 20vw !important;
}

.border-radius-24vw {
  border-radius: 24vw !important;
}

.border-radius-26vw {
  border-radius: 26vw !important;
}

.border-radius-28vw {
  border-radius: 28vw !important;
}

.border-radius-35vw {
  border-radius: 35vw !important;
}

.border-radius-40vw {
  border-radius: 40vw !important;
}
